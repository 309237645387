<template>
    <div class="hackathon-page" :class="{ 'no-projects': !hasProjects }">
        <ProjectsSidebar v-if="hasProjects" :projects="hackathonProjects" />

        <div class="hackathon" :class="{ 'has-projects': hasProjects }">
            <div class="scroll-wrap">
                <main>
                    <div v-if="loading" class="loading-spinner centered">
                        <DsSpinner />
                    </div>

                    <template v-else>
                        <div class="hackathon-header">
                            <img
                                v-if="hackathon.image"
                                :src="hackathon.image"
                                :alt="hackathon.name"
                                class="hackathon-image"
                            />

                            <h2 class="hackathon-name">
                                {{ hackathon.name }}
                                <span v-if="!isRally" class="hackathon-quarter">{{ hackathonQuarter }}</span>
                            </h2>

                            <p v-if="daysUntilHackathon > 0" class="hackathon-signup-reminder">
                                Registration ends in <strong>{{ daysUntilHackathon }} days</strong>.
                            </p>

                            <DsFilledButton
                                v-if="isHackathonActive"
                                @click="$router.push({ name: 'ideas.new' })"
                            >
                                Submit an idea
                            </DsFilledButton>

                            <vue-markdown
                                v-if="hackathon.description"
                                class="hackathon-description"
                                :source="hackathon.description"
                            />

                            <div v-if="hackathon.photos" class="hackathon-thumbnails">
                                <img
                                    v-for="(photo, index) in hackathon.photos"
                                    :key="index"
                                    :src="photo"
                                    @click="openModal(index)"
                                />
                            </div>

                            <div class="awards">
                                <DsInlineAlert
                                    v-for="(award, index) in hackathon.awards"
                                    :key="index"
                                    leading-icon="award-fill"
                                    type="warning"
                                >
                                    {{ award.name }}
                                    <small>{{ award.description }}</small>
                                </DsInlineAlert>
                            </div>

                            <DsModal
                                v-if="hackathon.photos"
                                :is-open="activePhotoIndex !== null"
                                :title="hackathon.name"
                                :sub-title="`Image ${activePhotoIndex + 1} of ${hackathon.photos.length}`"
                                show-close
                                @close="activePhotoIndex = null"
                            >
                                <img :src="hackathon.photos[activePhotoIndex]" />

                                <template #header>
                                    <DsFilledButton
                                        v-if="isAdmin"
                                        destructive
                                        @click="removePhotoCheck(hackathon.photos[activePhotoIndex])"
                                    >
                                        <DsStatus emphasis="high">
                                            Admin
                                        </DsStatus>

                                        Delete
                                    </DsFilledButton>

                                    <div
                                        v-if="hackathon.photos.length > 1"
                                        class="button-group responsive"
                                    >
                                        <DsIconButton
                                            name="chevron-left"
                                            @click="changePhoto('prev')"
                                        />

                                        <DsIconButton
                                            name="chevron-right"
                                            @click="changePhoto('next')"
                                        />
                                    </div>
                                </template>
                            </DsModal>
                        </div>

                        <div class="hackathon-actions">
                            <DsFilledButton
                                v-if="hasProjects && isAdmin"
                                gray
                                trailing-icon="download-cloud"
                                @click="csvExport"
                            >
                                <DsStatus emphasis="high">
                                    Admin
                                </DsStatus>

                                Download CSV
                            </DsFilledButton>

                            <DsFilledButton
                                v-if="isAdmin"
                                gray
                                trailing-icon="edit"
                                @click="$router.push({ name: 'edit.event', params: { id: hackathonId } })"
                            >
                                <DsStatus emphasis="high">
                                    Admin
                                </DsStatus>

                                Edit event
                            </DsFilledButton>

                            <DsFilledButton
                                v-if="isRally && isAdmin"
                                gray
                                @click="$router.push({ name: 'projects.new' })"
                            >
                                Create project
                                <DsStatus emphasis="high">
                                    Admin
                                </DsStatus>
                            </DsFilledButton>

                            <DsFilledButton
                                v-if="!isRally && isHackathonActive"
                                gray
                                @click="$router.push({ name: 'projects.new', query: { hackathonId } })"
                            >
                                Create project
                            </DsFilledButton>
                        </div>
                    </template>
                </main>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import moment from 'moment';
import ProjectsSidebar from '@/components/ProjectsSidebar';
import '@/assets/images/page-not-found.svg';
import VueMarkdown from 'vue-markdown';

export default {
    components: {
        ProjectsSidebar,
        VueMarkdown,
    },

    data() {
        return {
            loading: true,
            activePhotoIndex: null,
            uploader: {
                progress: 0,
                file: null,
                interval: 0,
            },
        };
    },

    mounted() {
        if (!this.hackathonId) return;

        this.loadHackathon();
    },

    beforeDestroy() {
        this.$store.commit('hackathons/CLEAR_HACKATHON');
    },

    watch: {
        hackathonId() {
            this.loadHackathon();
        },
    },

    computed: {
        ...mapState({
            hackathon: ({ hackathons }) => hackathons.hackathon,
            isAdmin: ({ auth }) => auth.user.isAdmin,
            projects: ({ projects }) => projects.projects,
        }),

        ...mapGetters({
            getProjectsByHackathon: 'projects/getProjectsByHackathon',
            getProject: 'projects/getProject',
            activeHackathon: 'hackathons/getActiveHackathon',
        }),

        hackathonId() {
            return this.$route.params.id;
        },

        hackathonProjects() {
            return this.getProjectsByHackathon(this.hackathonId);
        },

        isRally() {
            return this.activeHackathon?.type === 'rally';
        },

        hackathonStatus() {
            const today = moment();

            if (today.isBetween(this.hackathon?.startDate, this.hackathon?.endDate)) return 'ongoing';
            if (moment(this.hackathon?.endDate).isBefore(today)) return 'completed';
            if (moment(today).isBefore(this.hackathon?.startDate)) return 'planning';

            return this.hackathon?.startDate;
        },

        hasProjects() {
            return this.hackathonProjects?.length > 0;
        },

        hackathonQuarter() {
            return `Q${moment(this.hackathon.startDate).quarter()} ${moment(this.hackathon.startDate).year()}`;
        },

        daysUntilHackathon() {
            if (this.hackathonStatus !== 'planning') return null;

            return moment(this.hackathon.startDate).diff(moment(), 'days');
        },

        awardTitle() {
            const hasWinner = this.hackathon.awards.some(({ winner }) => winner);

            return hasWinner ? 'Winners' : 'Award categories';
        },

        isHackathonActive() {
            return ['planning', 'ongoing'].includes(this.hackathonStatus);
        },
    },

    methods: {
        csvExport() {
            const projectsData = this.hackathonProjects
                ?.filter((project) => !project.disablePresenting)
                ?.map((project) => project.name.replace(',', ' '));

            projectsData.unshift('Project name');

            let csvContent = 'data:text/csv;charset=utf-8,';

            csvContent += projectsData
                .join('\n')
                .replace(/(^\[)|(\]$)/gm, '');

            const data = encodeURI(csvContent);
            const link = document.createElement('a');

            link.setAttribute('href', data);
            link.setAttribute('download', `${this.hackathon.name}_projects.csv`);
            link.click();
        },

        selectProject(project) {
            this.$router.push({ name: 'project', params: { id: project.id } });
        },

        getHackathon(project) {
            return {
                ...this.getProject(project),
                image: project.thumbnail,
            };
        },

        formatDate(date) {
            return moment(date).format('MM/DD/YYYY');
        },

        async loadHackathon() {
            try {
                this.loading = true;

                await this.$store.dispatch('hackathons/LOAD_HACKATHON', this.hackathonId);
                await this.$store.dispatch('hackathons/LOAD_HACKATHON_COMMENTS');
                await this.$store.dispatch('projects/LOAD_PROJECTS');
            } catch (e) {
                this.$error({ message: 'Error loading hackathon', bottom: true });
                this.$router.replace({ name: 'home' });
            }

            this.loading = false;
        },

        removePhotoCheck(photo) {
            this.$confirm({
                title: 'Delete',
                message: 'Are you sure you want to delete this?',
                confirmLabel: 'Yes, delete',
                destructive: true,
            }).then(() => {
                this.removePhoto(photo);
            }).catch(() => {});
        },

        removePhoto(photo) {
            const photos = this.hackathon.photos.filter((photoUrl) => photoUrl !== photo);

            this.$store.dispatch('hackathons/EDIT_HACKATHON', { ...this.hackathon, photos });

            this.$refs.photosModal.toggleable_close();
        },

        openModal(index) {
            this.activePhotoIndex = index;
        },

        changePhoto(direction) {
            const photoCount = this.hackathon.photos.length - 1;

            if (direction === 'next') {
                this.activePhotoIndex = this.activePhotoIndex < photoCount
                    ? this.activePhotoIndex + 1
                    : 0;
            } else if (direction === 'prev') {
                this.activePhotoIndex = this.activePhotoIndex === 0
                    ? photoCount
                    : this.activePhotoIndex - 1;
            }
        },
    },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.hackathon-page {
    display: grid;
    grid-template-columns: 400px 1fr;
    grid-gap: $spacing-200;
    align-items: center;
    max-height: calc(100vh - 72px);

    &.no-projects {
        grid-template-columns: 1fr;
    }

    @media($small) {
        grid-template-columns: 1fr;
    }
}

.scroll-wrap {
    height: calc(100vh - 104px);
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.hackathon {
    display: flex;
    flex-direction: column;
    overflow: auto;
    background: $color-gray-050;
    border-radius: $border-radius;

    &.has-projects {
        @media($small) {
            display: none;
        }
    }
}

main {
    overflow: auto;
    height: calc(100vh - 104px);
    display: flex;
    flex-direction: column;

    @include custom-scroll-bar;

    @media($extra-small) {
        display: flex;
        flex-direction: column;
    }
}

.projects {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: $spacing-200;
    padding-bottom: $spacing-200;
}

.projects-toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $color-gray-050;
    padding: $spacing-200;

    @media($extra-small) {
        flex-direction: column;
        grid-gap: $spacing-200;
    }
}

.hackathon-actions {
    display: flex;
    gap: $spacing-200;
    align-items: center;
    justify-content: center;
}

.hackathon-header {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: $spacing-800 $spacing-200 $spacing-200;
}

.hackathon-image {
    border-radius: $border-radius;
    min-width: 240px;
    max-width: 400px;
    max-height: 50vh;
    height: auto;

    @media($extra-small) {
        width: 100%;
        min-width: auto;
    }
}

h2, h4 {
    font-weight: bold;

    @media($extra-small) {
        text-align: center;
    }
}

.title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: $spacing-100;

    @media($small) {
        display: grid;
        grid-template-columns: auto;
    }
}

.hackathon-thumbnails {
    display: flex;
    align-items: center;
    flex-direction: row;
    overflow-x: auto;
    margin: $spacing-200;
    @include custom-scroll-bar;

        img {
            max-height: 100px;
            border-radius: calc($border-radius / 2);
            margin-right: $spacing-200;
        }
    }

    .button-group {
        margin-right: $spacing-100;
    }

    .voting {
        margin-right: $spacing-200;
    }

    .awards {
        display: flex;
        grid-gap: $spacing-200;

        @media($extra-small) {
            flex-direction: column;
            grid-gap: 0;
        }
    }

    .hackathon-name {
        margin: $spacing-200 0;
    }

    .hackathon-description {
        max-width: 60vw;
        text-align: justify;
    }

    .hackathon-quarter {
        color: $color-blue-400;
    }

    .hackathon-signup-reminder {
        color: $color-orange;
        font-size: $font-size-xl;
    }
</style>
