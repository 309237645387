<template lang="html">
    <div>
        <portal to="title">
            {{ title }}
        </portal>

        <div v-if="loading" class="loading-spinner">
            <DsSpinner />
        </div>

        <DsInlineAlert
            v-else-if="!activeHackathon && !isIdea && !editing"
            type="warning"
            leading-icon
        >
            There's no active hackathon at the moment.

            <template #footer>
                <div class="button-row">
                    <DsFilledButton
                        gray
                        @click="$router.push({ name: 'ideas' })"
                    >
                        Browse Ideas
                    </DsFilledButton>

                    <DsFilledButton
                        gray
                        @click="$router.push({ name: 'ideas.new' })"
                    >
                        Submit idea
                    </DsFilledButton>

                    <DsFilledButton
                        gray
                        @click="$router.push({ name: 'projects' })"
                    >
                        Browse Projects
                    </DsFilledButton>
                </div>
            </template>
        </DsInlineAlert>

        <form v-else @submit.prevent="submit">
            <DsInlineAlert v-if="editing && isAdmin && !isProjectMember" leading-icon type="warning">
                <div>
                    Warning: this is not your {{ isIdea ? 'idea' : 'project' }}. You can use your
                    <DsStatus emphasis="high">
                        Admin
                    </DsStatus>

                    privileges to edit it.
                </div>
            </DsInlineAlert>

            <div v-if="!isIdea" class="hackathon-card">
                <router-link :to="{ name: 'event', params: { id: projectHackathon.id } }">
                    <img :src="projectHackathon.image" alt="" class="hackathon-image" />
                </router-link>

                <div>
                    <DsStatus emphasis="high">
                        {{ eventName }}
                    </DsStatus>

                    <h4>{{ projectHackathon.name }}</h4>
                </div>
            </div>

            <DsInlineAlert
                v-if="isIdea && !editing"
                leading-icon
                type="warning"
            >
                Submission does not guarantee approval.
            </DsInlineAlert>

            <div class="checkboxes">
                <DsCheckbox
                    v-if="isAdmin && isRally"
                    v-model="project.approved"
                    label="Approved"
                >
                    <div class="approved-label">
                        Approved
                    </div>

                    <DsStatus emphasis="high">
                        Admin
                    </DsStatus>
                </DsCheckbox>

                <DsCheckbox
                    v-if="isRally && editing"
                    v-model="project.completed"
                    label="Completed"
                />

                <DsCheckbox
                    v-if="editing && !isIdea"
                    v-model="project.disablePresenting"
                    label="Not presenting"
                />
            </div>

            <DsInputField
                v-model="project.name"
                :label="isIdea ? 'Idea title' : 'Project/Team Name'"
                required
                :submitted="submitted"
            />

            <DsTextAreaField
                v-model="project.shortDescription"
                :label="isIdea ? 'Idea description' : 'Project description'"
                required
                :submitted="submitted"
                auto-grow
            >
                <div slot="help">
                    Markdown supported
                </div>
            </DsTextAreaField>

            <DsTextAreaField
                v-if="!isIdea || isRally"
                v-model="project.longDescription"
                label="Business value"
                auto-grow
                required
                :submitted="submitted"
            >
                <div slot="help">
                    Markdown supported
                </div>
            </DsTextAreaField>

            <DsCheckbox
                v-if="isIdea && !editing && !isRally"
                v-model="anonymous"
                label="Anonymous"
            />

            <DsInlineAlert v-if="anonymous" leading-icon type="subtle">
                You won't receive attribution or credit for your idea.
            </DsInlineAlert>

            <div v-if="!isIdea" class="optional-fields">
                <DsMultiselect
                    v-model="project.teamLead"
                    searchable
                    placeholder="Team lead"
                    bind-value-only
                    :options="users"
                />

                <DsMultiselect
                    v-model="project.members"
                    multiple
                    searchable
                    placeholder="Team members"
                    bind-value-only
                    :options="getUsers"
                />

                <DsInputField
                    v-model="project.memberCapacity"
                    label="Member capacity"
                    type="number"
                    :max="15"
                    :min="0"
                    :submitted="submitted"
                />

                <DsMultiselect
                    v-model="project.rolesWanted"
                    multiple
                    searchable
                    placeholder="Roles wanted"
                    bind-value-only
                    :options="formattedRoles"
                />

                <DsMultiselect
                    v-model="project.tags"
                    multiple
                    searchable
                    placeholder="Tags"
                    bind-value-only
                    :options="tags"
                />

                <DsInputField
                    v-model="project.slackChannelId"
                    label="Slack Channel ID (e.g. C9L8V6D42)"
                />
            </div>

            <DsTextAreaField
                v-if="!isIdea || isRally"
                v-model="project.notes"
                label="Notes"
                auto-grow
            >
                <div slot="help">
                    Markdown supported
                </div>
            </DsTextAreaField>

            <template v-if="editing">
                <h5>Project image / thumbnail</h5>

                <DsListItem
                    v-if="project.thumbnail"
                    border-style="full"
                >
                    <template #leading>
                        <img
                            :src="project.thumbnail"
                            class="project-image"
                            @click="isOpen = true"
                        />
                    </template>

                    <template #title>
                        <p>Project image / thumbnail</p>

                        <DsFilledButton
                            name="trash-2"
                            gray
                            @click="confirmThumbnailDelete"
                        >
                            Remove project image
                        </DsFilledButton>
                    </template>
                </DsListItem>

                <DsFileUploadInput
                    v-if="showThumbnailUpload"
                    ref="thumbnailUploader"
                    v-model="thumbnailUploader.file"
                    accept="image/*"
                    :progress.sync="thumbnailUploader.progress"
                    allowed-files-label="Project image will be used as a project thumbnail"
                    label="Upload project image"
                    :max-file-size-bytes="maxFileSize"
                    @input="uploadThumbnail"
                    @error="handleError"
                />

                <h5>Additional project images</h5>

                <DsListItem
                    v-for="(photo, index) in project.photos"
                    :key="index"
                    border-style="full"
                >
                    <template #leading>
                        <img
                            :src="photo"
                            class="project-image"
                            @click="isOpen = true"
                        />
                    </template>

                    <template #title>
                        <DsFilledButton
                            name="trash-2"
                            gray
                            @click="confirmDelete(photo)"
                        >
                            Remove image
                        </DsFilledButton>
                    </template>
                </DsListItem>

                <DsFileUploadInput
                    v-if="isProjectMember || isAdmin"
                    ref="photoUploader"
                    v-model="photoUploader.file"
                    :progress.sync="photoUploader.progress"
                    :max-file-size-bytes="maxFileSize"
                    label="Upload additional project images"
                    @input="uploadPhoto($event)"
                    @error="handleError"
                />
            </template>

            <div class="actions">
                <DsFilledButton
                    type="submit"
                    :loading="saving"
                >
                    {{ actionText }}
                </DsFilledButton>

                <DsFilledButton
                    v-if="editing"
                    type="button"
                    destructive
                    outline
                    @click.prevent="deleteConfirm"
                >
                    Delete
                </DsFilledButton>
            </div>
        </form>
    </div>
</template>

<script>
import { roles } from '@/components/project/constants';
import moment from 'moment';
import { mapState, mapGetters } from 'vuex';

export default {
    data() {
        return {
            loading: false,
            submitted: false,
            saving: false,
            anonymous: false,
            roles,
            project: {},
            maxFileSize: 10485760,
            uploadingPhoto: false,
            thumbnailUploader: {
                progress: 0,
                file: null,
                interval: 0,
            },
            photoUploader: {
                progress: 0,
                file: null,
                interval: 0,
            },
        };
    },

    mounted() {
        this.load();

        if (Object.keys(this.$route.query).length) {
            this.$router.replace({ query: null });
        }
    },

    computed: {
        ...mapState({
            isAdmin: ({ auth }) => auth.user.isAdmin,
            hackathons: ({ hackathons }) => hackathons.hackathons,
            users: ({ users }) => users.users,
            user: ({ auth }) => auth.user,
        }),

        ...mapGetters({
            tags: 'tags/getTags',
            getUsers: 'users/getUsers',
            getHackathons: 'hackathons/getHackathons',
            getHackathon: 'hackathons/getHackathon',
            activeHackathon: 'hackathons/getActiveHackathon',
        }),

        isRally() {
            return this.activeHackathon.type === 'rally';
        },

        eventName() {
            return this.isRally
                ? 'Rally'
                : 'Hackathon';
        },

        showThumbnailUpload() {
            return !this.project?.thumbnail && (this.isProjectMember || this.isAdmin);
        },

        isProjectMember() {
            const isTeamMember = this.project?.members?.includes(this.user.uid);
            const isTeamLead = this.project?.teamLead === this.user.uid;

            return isTeamMember || isTeamLead;
        },

        users() {
            return [{ value: '', label: '' }, ...this.getUsers];
        },

        editing() {
            return ['projects.edit', 'ideas.edit'].includes(this.$route.name);
        },

        formattedRoles() {
            return Object.entries(this.roles).map(([value, label]) => {
                return { value, label };
            });
        },

        isIdea() {
            return ['ideas.new', 'idea', 'ideas.edit'].includes(this.$route.name) && (!this.project.hackathonId || this.isRally);
        },

        projectHackathon() {
            if (this.isIdea) return '';
            if (this.editing) return this.getHackathon(this.project.hackathonId);

            return this.activeHackathon;
        },

        actionText() {
            if (this.editing) return 'Save';

            return this.isIdea
                ? 'Submit idea'
                : 'Create project';
        },

        title() {
            const title = this.isIdea ? 'idea' : 'project';
            const createText = this.isIdea ? 'Submit' : 'Create';

            return this.editing
                ? `Edit ${title}`
                : `${createText} ${title}`;
        },

        hackathonOptions() {
            return [{ value: '', label: '' }, ...this.getHackathons];
        },

        projectId() {
            return this.$route.params.id;
        },

        eventId() {
            return this.activeHackathon?.type === 'rally' || !this.isIdea
                ? this.activeHackathon?.id
                : '';
        },
    },

    methods: {
        async load() {
            this.loading = true;

            try {
                if (!this.activeHackathon) await this.$store.dispatch('hackathons/LOAD_HACKATHONS');

                this.loadProject();
            } catch (e) {
                this.$error({ message: 'Error loading hackathons data', bottom: true });
                this.$router.push({ name: 'home' });
            }
        },

        confirmThumbnailDelete() {
            this.$confirm({
                title: 'Delete project image',
                message: 'Are you sure you want to delete this?',
                confirmLabel: 'Yes, delete',
                destructive: true,
            }).then(() => {
                this.$store.dispatch('projects/SAVE_PROJECT', { projectId: this.project.id, project: { ...this.project, thumbnail: '' } });
                this.reloadProject();
            }).catch(() => {});
        },

        confirmDelete(photo) {
            this.$confirm({
                title: 'Delete',
                message: 'Are you sure you want to delete this?',
                confirmLabel: 'Yes, delete',
                destructive: true,
            }).then(() => {
                this.removePhoto(photo);
            }).catch(() => {});
        },

        removePhoto(photo) {
            const photos = this.project.photos.filter((photoUrl) => photoUrl !== photo);

            this.$store.dispatch('projects/SAVE_PROJECT', { projectId: this.project.id, project: { ...this.project, photos } });
            this.reloadProject();
        },

        handleError(error) {
            this.$error({ message: `Error uploading: '${error}'` });
        },

        async uploadPhoto(file) {
            if (file == null) {
                return null;
            }

            this.uploadingPhoto = true;

            await this.$store.dispatch('projects/ADD_PROJECT_PHOTO', file)
                .catch(() => {
                    this.$refs.photoUploader.reset();
                    this.uploadingPhoto = false;
                    this.handleError();
                });

            this.$refs.photoUploader.reset();
            this.uploadingPhoto = false;
            this.reloadProject();

            return this.$toast({ message: 'Photo added' });
        },

        async reloadProject() {
            this.loading = true;

            this.$nextTick(() => {
                setTimeout(async () => {
                    this.project = await this.$store.dispatch('projects/LOAD_PROJECT', this.projectId);
                    this.loading = false;
                    this.$forceUpdate();
                }, 1000);
            });
        },

        async loadProject() {
            if (!this.editing) {
                this.project = {
                    createdDate: '',
                    // hackathonId: 'vQwocxNd34vnnufXkRrS', // uncomment to override hackathon
                    hackathonId: this.eventId,
                    image: '',
                    memberCapacity: null,
                    lastUpdated: '',
                    longDescription: '',
                    teamLead: '',
                    completed: false,
                    approved: false,
                    members: [],
                    name: '',
                    notes: '',
                    photos: [],
                    thumbnail: '',
                    rolesWanted: [],
                    shortDescription: '',
                    tags: [],
                    slackChannelId: '',
                };

                if (this.isIdea) {
                    this.project.suggestedBy = this.user.uid;
                }

                this.loading = false;

                return;
            }

            try {
                this.loading = true;

                this.project = await this.$store.dispatch('projects/LOAD_PROJECT', this.projectId);
            } catch (e) {
                this.$error({ message: 'Error loading project', bottom: true });
            }

            this.loading = false;
        },

        async uploadThumbnail(file) {
            if (file == null) return null;

            try {
                const { project } = this;

                await this.$store.dispatch('projects/ADD_PROJECT_THUMBNAIL', { file, project });

                this.$toast({ message: 'Project image uploaded' });
            } catch (e) {
                this.$refs.thumbnailUploader.reset();
                this.handleError(e);
            }

            this.$refs.thumbnailUploader.reset();

            return this.reloadProject();
        },

        deleteConfirm() {
            this.$confirm({
                title: 'Delete',
                message: `Are you sure you want to delete this ${this.isIdea ? 'idea' : 'project'}?`,
                confirmLabel: 'Yes, delete',
            })
                .then(() => {
                    this.deleteProject();
                })
                .catch(() => {});
        },

        submit() {
            if (this.editing) {
                this.saveProject();
            } else {
                this.createProject();
            }
        },

        async createProject() {
            const createdDate = moment().format();

            this.project.ideaAuthor = this.isIdea && !this.anonymous ? this.user.uid : null;
            this.project.createdDate = createdDate;
            this.project.lastUpdated = createdDate;

            await this.$store.dispatch('projects/CREATE_PROJECT', this.project)
                .catch((e) => {
                    this.$error({ message: `Error creating project: ${e}` });
                });

            this.$toast({ headline: this.isIdea ? 'Idea submitted' : ' Project created' });

            if (this.isIdea && !this.isRally) {
                this.$router.push({ name: 'ideas' });
            } else {
                this.$router.push({ name: 'event', params: { id: this.project.hackathonId } });
            }
        },

        async saveProject() {
            this.saving = true;
            const { projectId, project } = this;

            await this.$store.dispatch('projects/SAVE_PROJECT', { projectId, project })
                .catch((e) => {
                    this.$error({ message: `Error saving ${this.isIdea ? 'idea' : 'project'}: ${e}` });
                });

            this.saving = false;
            this.$toast({ headline: `${this.isIdea ? 'Idea' : 'Project'} saved` });
            this.$router.push({ name: this.isIdea ? 'idea' : 'project', params: { id: projectId } });
        },

        async deleteProject() {
            try {
                await this.$store.dispatch('projects/DELETE_PROJECT', this.projectId);
                this.$toast({ headline: `${this.isIdea ? 'Idea' : 'Project'} deleted` });
                this.$router.push(this.project.hackathonId ? { name: 'event', params: { id: this.project.hackathonId } } : { name: 'home' });
            } catch (e) {
                this.$error({ message: `Error deleting ${this.isIdea ? 'idea' : 'project'}: ${e}` });
                this.$router.push({ name: 'home' });
            }
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: $spacing-200;
}

.optional-fields {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 0;
    grid-column-gap: $spacing-200;

    @media($small) {
        grid-template-columns: 1fr;
    }
}

.hackathon-card {
    display: flex;
    align-items: center;
    grid-gap: $spacing-200;
    padding: $spacing-200;
    background: $color-gray-100;
    margin-bottom: $spacing-200;
    border-radius: $border-radius;

    img {
        border-radius: $border-radius;
        height: 100px;
    }
}

.file-upload {
    margin-bottom: $spacing-200;
}

.project-image {
    width: 100px;
    height: auto;
    border-radius: $border-radius;
}

.approved-label {
    margin-right: $spacing-100;
}

.checkboxes {
    display: inline-flex;
    margin-bottom: 2rem;
}
</style>
